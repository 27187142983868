import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImageFluidProps } from 'gatsby-image'
import { useTranslation } from 'react-i18next'

import { withTranslationHOC } from '../i18n/with-translation-hoc'
import { Region, Layout, Main, LoginInput } from '../components'

interface IUnauthorisedPageProps {
  location: {
    pathname: string
  }
  data: {
    image: {
      childImageSharp: GatsbyImageFluidProps
    }
  }
}

const Unauthorised = memo(
  ({ data: { image }, location }: IUnauthorisedPageProps) => {
    const { t } = useTranslation()

    return (
      <Layout location={location}>
        <Region
          defaultUser={{
            name: t('notLoggedIn.profileName'),
            image,
            role: '',
            id: '',
          }}
          pathname={location.pathname}
        />

        <Main>
          <LoginInput />
        </Main>
      </Layout>
    )
  }
)

Unauthorised.displayName = 'Unauthorised'

export default withTranslationHOC(Unauthorised)

export const data = graphql`
  query UnauthorisedPageQuery {
    image: file(relativePath: { eq: "default.png" }) {
      ...fluidImage
    }
  }
`
